<template>

  <div>
    <b-row v-if="mailData">
      <b-col md="12">
        <b-card
          :title="$t('E Posta Gönderim Detayı')"
        >
          <b-card-header

            class="email-detail-head"
          >
            <b-table-simple

              small
              caption-top
              responsive
            >

              <b-thead>

                <b-tr>
                  <b-th>{{ $t('Oluşturma Zamanı') }}</b-th>
                  <b-th>{{ $t('Mail Zamanı') }}</b-th>
                  <b-th>{{ $t('Gönderici') }}</b-th>
                  <b-th>{{ $t('İşlem Yapan') }}</b-th>
                  <b-th>{{ $t('Toplam Alıcı') }}</b-th>
                  <b-th>{{ $t('Giden') }}</b-th>
                  <b-th>{{ $t('Gitmeyen') }}</b-th>
                  <b-th>{{ $t('Belirsiz') }}</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td>{{ $format_datetime(mailData.created_at) }}</b-td>
                  <b-td>{{ mailData.schedule_time }}</b-td>
                  <b-td>{{ mailData.from_email }}</b-td>
                  <b-td>{{ mailData.transaction_user }}</b-td>
                  <b-td>{{ mailData.total_mails }}</b-td>
                  <b-td>{{ mailData.success_mails }}</b-td>
                  <b-td>{{ mailData.error_mails }}</b-td>
                  <b-td>{{ mailData.not_responsing_mails }}</b-td>
                </b-tr>

              </b-tbody>
            </b-table-simple>
            <hr>
            <h3>{{ $t('Konu') }}: {{ mailData.subject }}</h3>

          </b-card-header>

          <b-card-body class="mail-message-wrapper pt-2">
            <!-- eslint-disable vue/no-v-html -->
            <div
              class="mail-message"
              v-html="mailData.content"
            />
            <!-- eslint-enable -->
          </b-card-body>

          <b-card-footer v-if="mailData.attachments && mailData.attachments.length">
            <div class="mail-attachments">
              <div class="d-flex align-items-center mb-1">
                <feather-icon
                  icon="PaperclipIcon"
                  size="16"
                />
                <h5 class="font-weight-bolder text-body mb-0 ml-50">
                  {{ mailData.attachments.length }} Dosya{{ mailData.attachments.length > 1 ? 'lar' : '' }}
                </h5>
              </div>

              <div class="d-flex flex-column">
                <b-link
                  v-for="(attachment, index) in mailData.attachments"
                  :key="index"
                  target="_blank"
                  :class="{'mt-75': index}"
                >

                  <span class="text-muted font-weight-bolder align-text-top">{{ attachment.name }}</span>
                </b-link>
              </div>
            </div>
          </b-card-footer>
        </b-card>
      </b-col>
      <b-col md="12">
        <b-card
          :title="$t('Alıcı E Posta Adresleri')"
        >
          <b-card-body class="mail-message-wrapper pt-2">
            <email-log-detail-records :data="mailData" /></b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BCardBody, BCardHeader, BLink, BCardFooter, BTableSimple, BThead, BTbody, BTr, BTd, BTh,
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import emailStoreModule from '../../emailStoreModule'
import emailLogDetailRecords from './EmailLogDetailRecords.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardBody,
    BCardHeader,
    BLink,
    emailLogDetailRecords,
    BCardFooter,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTd,
    BTh,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
    }
  },
  methods: {

  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-email'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, emailStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const mailData = ref(null)
    if (router.currentRoute.params.id !== undefined) {
      store.dispatch('app-email/fetchSingleEmailSendData', { id: router.currentRoute.params.id })
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          mailData.value = response.data.data
        })
        .catch(() => {
          alert('Hata Oluştu')
        })
    }

    return {
      mailData,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
