<template>

  <div>

    <!-- Filters -->

    <!-- Table Container Card -->
    <b-card

      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('Entries') }}</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input

                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Email Konusu Arayın..."
              />

            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refEmailLogListTable"
        class="position-relative text-xsmall"
        :items="fetchEmailCategories"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(created_at)="data">
          {{ $format_datetime(data.item.created_at) }}
        </template>
        <template #cell(event)="data">
          <b-badge
            v-if="data.item.type==0"
            variant="warning"
          >
            {{ $t('Servisten Yanıt Gelmedi') }}
          </b-badge>
          <b-badge
            v-else-if="data.item.event==1"
            variant="success"
          >
            {{ $t('İletildi') }}
          </b-badge>
          <b-badge
            v-else-if="data.item.event!=1"
            variant="danger"
          >
            {{ $t('Başarısız') }}
          </b-badge>
        </template>
        <!-- Column: Actions -->

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('Gösterilen') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('Entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalEmailLogList"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BBadge, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import { avatarText } from '@core/utils/filter'
import useEmailSendRecordsList from './useEmailSendRecordsList'
import emailStoreModule from '../../emailStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BBadge,
    BTable,
    BPagination,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  methods: {

  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-email'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, emailStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
    const editEmailLogListId = ref(0)
    const isAddNewEmailLogListSidebarActive = ref(false)
    const isEditEmailLogListSidebarActive = ref(false)
    const emailLogListData = ref(null)
    const blankEmailLogListData = {
      id: 0,
      email: '',
      category_id: router.currentRoute.params.category_id,
    }
    const {
      fetchEmailCategories,
      tableColumns,
      perPage,
      currentPage,
      totalEmailLogList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refEmailLogListTable,
      refetchData,
      // UI

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useEmailSendRecordsList()
    const resetEmailLogListData = () => {
      emailLogListData.value = JSON.parse(JSON.stringify(blankEmailLogListData))
    }
    resetEmailLogListData()
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetEmailLogListData)
    return {

      // Sidebar
      isAddNewEmailLogListSidebarActive,
      isEditEmailLogListSidebarActive,
      fetchEmailCategories,
      tableColumns,
      perPage,
      currentPage,
      totalEmailLogList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      refFormObserver,
      emailLogListData,
      resetEmailLogListData,
      getValidationState,
      resetForm,
      editEmailLogListId,
      isSortDirDesc,
      refEmailLogListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      blankEmailLogListData,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
